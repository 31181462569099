<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-19 21:31:58
-->
<template>
    <h1>主页</h1>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    
</style>